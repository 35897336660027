import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Icon } from "@iconify/react";
import { Box, Typography, IconButton } from "@mui/material";
import closeFill from "@iconify/icons-eva/close-fill";
export default function CustomPopover({
  isOpenPop,

  isClosePop,
  title,
  componentToPassDown,
  heigh,
  width,
}) {
  const handleClose = () => {
    isClosePop(false);
  };

  return (
    <Dialog
      maxWidth="md"
      sx={{
        // ml: 35,
        "@media (max-width: 768px)": {
          ml: 0,
          // width: "100%",
        },
      }}
      open={isOpenPop}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: "#ffffff",
          color: "black",
          boxShadow: "none",
          minWidth: width ? heigh : "950px",
          minHeight: heigh ? heigh : "500px",
        },
      }}
    >
      <Box>
        <div className="custom-modal mt-2 px-4 pt-3">
          <div>
            <Typography
              variant="h5"
              component="h2"
              sx={{ color: "var(--portal-theme-primary)" }}
            >
              {title}
            </Typography>
          </div>
          <div>
            <IconButton onClick={handleClose} className="icon-button-modal">
              <Icon
                icon={closeFill}
                width={20}
                height={20}
                style={{ fill: "var(--portal-theme-primary)" }}
              />
            </IconButton>
          </div>
        </div>

        <div>{componentToPassDown}</div>
      </Box>
    </Dialog>
  );
}
