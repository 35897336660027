import React, { useContext, useState, useCallback } from "react";
import { _get_user_from_localStorage } from "../DAL/localstorage/LocalStorage";
import io from "socket.io-client";
import { socketUrl } from "src/config/config";

const CreateContentSetting = React.createContext();
const get_user = _get_user_from_localStorage();
export const useContentSetting = () => useContext(CreateContentSetting);

let socket = {};
socket = io(socketUrl + `?user_id=${get_user._id}&role=admin`);

export function ContentSettingState({ children }) {
  /* ------------------------------------------------------
  ------------------| States |--------------------
  ------------------------------------------------------- */

  const [userInfo, setUserInfo] = useState(get_user);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [userExerciseHistory, setUserExerciseHistory] = useState([]);
  const [userExerciseHistoryLoading, setUserExerciseHistoryLoading] =
    useState(false);
  const [selectedExercise, setSelectedExercise] = useState("");
  const [googleClientId, setGoogleClientId] = useState(
    "48261507008-nhiup1us5qbdq528d5j0gpe0ac87b27t.apps.googleusercontent.comss"
  );

  /* ------------------------------------------------------
------------------/ Hooks Functions /-------------------
  ------------------------------------------------------- */

  const handleSetUserInfo = (val) => {
    setUserInfo(val);
  };

  const handleUnreadMessageCount = (val) => {
    setUnreadMessageCount(val);
  };

  const START_SOCKET_FORCEFULLY = (user_id) => {
    socket = io(socketUrl + `?user_id=${user_id}&role=admin`);
  };

  const STOP_SOCKET_FORCEFULLY = () => {
    socket.disconnect();
  };

  const handleGoogleId = (val) => {
    setGoogleClientId(val);
  };

  const setUserExerciseHistoryCallback = useCallback((val) => {
    setUserExerciseHistory(val);
  }, []);

  const collection = {
    handleSetUserInfo,
    userInfo,
    setUserInfo,
    userExerciseHistory,
    userExerciseHistoryLoading,
    setUserExerciseHistoryLoading,
    selectedExercise,
    setSelectedExercise,
    handleUnreadMessageCount,
    unreadMessageCount,
    setUserExerciseHistory: setUserExerciseHistoryCallback,
    socket,
    START_SOCKET_FORCEFULLY,
    STOP_SOCKET_FORCEFULLY,
    handleGoogleId,
    googleClientId,
  };
  return (
    <CreateContentSetting.Provider value={collection}>
      {children}
    </CreateContentSetting.Provider>
  );
}
