import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import {
  addCalendarSettingApi,
  // addStripeApi,
  // siteSettingApi,
} from "src/DAL/SiteSetting/siteSetting";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function CalendarSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const [inputs, setInputs] = useState({
    client_id: "",
    client_secret: "",
    redirect_url: "",
    is_calendar_enabled: false,
    delegate_portal_redirect_url: "",
    is_calendar_enabled_for_delegate: false,
  });
  const siteSettingData = async () => {
    setIsLoading(true);
    const result = await siteSettingApi();
    if (result.code == 200) {
      setInputs(result?.site_setting);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);

    let postData = {
      client_id: inputs.client_id,
      client_secret: inputs.client_secret,
      redirect_url: inputs.redirect_url,
      is_calendar_enabled: inputs.is_calendar_enabled,
      delegate_portal_redirect_url: inputs.delegate_portal_redirect_url,
      is_calendar_enabled_for_delegate: inputs.is_calendar_enabled_for_delegate,
    };

    const result = await addCalendarSettingApi(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // useEffect(() => {
  //   siteSettingData();
  // }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-6">
          <h2>Google Calendar Setting</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Client Secret"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="client_secret"
              value={inputs.client_secret}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Google Client Id"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="client_id"
              value={inputs.client_id}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Redirect Url"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="redirect_url"
              value={inputs.redirect_url}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Calendar Enable
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_calendar_enabled"
                value={inputs.is_calendar_enabled}
                label="Calendar Enable"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Coach Portal Redirect Url"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="delegate_portal_redirect_url"
              value={inputs.delegate_portal_redirect_url}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Is Calendar Enable For Coach
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_calendar_enabled_for_delegate"
                value={inputs.is_calendar_enabled_for_delegate}
                label="Is Calendar Enable For Coach"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
