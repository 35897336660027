import React, { useState, useEffect } from "react";
// import FormsDrewer from "../../components/FormsDrewer/FormsDrewer";

import "@fullcalendar/daygrid/main.css";
import moment from "moment";

import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import GeneralCalendar from "src/components/GeneralComponents/GeneralCalendar";
import { programme_workout_list_api } from "src/DAL/programmeWorkout/programmeWorkout";
import GeneralCkeditor from "src/components/GeneralCkeditor";
// import CustomDrawer from "src/components/FormsDrewer/CustomDrawer";
// import { _get_content_setting_localStorage } from "src/DAL/localStorage/localStorage";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const get_view_dates = (calendarValue) => {
  let tool = calendarValue;
  // if(calendarValue=="month")

  const startOfMonth = moment().startOf(tool);
  // console.log(startOfMonth, "asfjlks");
  const daysToSubtract = startOfMonth.day() - 0; // 0 represents Sunday
  const previousSunday = startOfMonth.subtract(daysToSubtract, "days");
  let previousSundayDate = previousSunday.toDate();
  let add_days = 42;
  if (tool == "week") {
    add_days = 6;
  } else if (tool == "day") {
    add_days = 0;
  }
  let nextSaturday = startOfMonth.add(add_days, "days");
  let nextSaturdayDate = nextSaturday.toDate();
  if (tool == "day") {
    previousSundayDate = new Date();
    nextSaturdayDate = new Date();
  }

  return {
    start_date: previousSundayDate,
    end_date: nextSaturdayDate,
  };
};
function Calenders(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [workouts, setworkouts] = useState([]);
  const navigate = useNavigate();
  const { slug } = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isPasted, setIsPasted] = useState(false);

  const classes = useStyles();
  const [defaultTimeZone, setDefaultTimeZone] = useState("");
  const [selectedTool, setSelectedTool] = useState("month");
  const [currentDate, setCurrentDate] = useState(get_view_dates("month"));

  const handleScrollCalled = (data) => {
    const todayCell = document.querySelector(
      `.calendar-workout-card[id="${data}"]`
    ); // Find the DOM element corresponding to today's date
    if (todayCell)
      todayCell.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const getEventListing = async (reloadId, message, setOpenCopyPasteConfig) => {
    if (selectedTool !== "month") {
      setIsLoading(true);
    }
    let postData = {
      start_date: moment(currentDate.start_date).format("YYYY-MM-DD"),
      end_date: moment(currentDate.end_date).format("YYYY-MM-DD"),
      slug: slug,
    };
    console.log(postData, "sdflksjkldfa");
    const result = await programme_workout_list_api(postData);
    if (result.code === 200) {
      let all_events = [];
      setDefaultTimeZone("asia/karachi");
      console.log(result, "dsafjsdjkaf");
      setworkouts(result?.program_workouts);
      setIsLoading(false);
      setIsPasted(false);
      if (reloadId) {
        handleScrollCalled(reloadId);
      }
      if (message) {
        enqueueSnackbar(message, { variant: "success" });
      }
      if (setOpenCopyPasteConfig) {
        setOpenCopyPasteConfig(false);
      }
    } else {
      setIsLoading(false);
      setIsPasted(false);
    }
  };

  useEffect(() => {
    getEventListing();
  }, [currentDate]);

  const content_setting = "";
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="col-12">
          <IconButton
            className="back-screen-button remove-margen"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <GeneralCalendar
        programWorkoutList={workouts}
        setCurrentDate={setCurrentDate}
        showAddEvent={true}
        time_zone={defaultTimeZone}
        currentDate={currentDate}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        reloadList={getEventListing}
        calendar_title={state?.title}
        programme={state}
        isPasted={isPasted}
        setIsPasted={setIsPasted}
      />
    </>
  );
}

export default Calenders;
