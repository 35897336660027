import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

// material
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RecordNotFound from "src/components/RecordNotFound";
import { ExerciseListingApi } from "src/DAL/exercise/exercise";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import ExerciseFilter from "./ExerciseFilterNew";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import FilteredChip from "src/components/FilteredChip";
import ToshowAddExercise from "./modalToshowAddExercise";
import { ExerciseTagesApi } from "src/DAL/exerciseType/exerciseType";
import Label from "src/components/Label";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { menuBar, menuBarTwo } from "src/assets";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TableRowsIcon from "@mui/icons-material/TableRows";
import GridOnIcon from "@mui/icons-material/GridOn";
import CustomPopover from "./CustomPopover";
import ReactVideoPlayer from "../../../components/ReactVideoPlayer/ReactVideoPlayer";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const ProgrammesImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});
const tabs = {
  all: "",
  asc: "asc",
  close: "desc",
  trash: "trash",
};
function ToshowExercise({ open, setOpen, handleChange, isWithHistory }) {
  const handleCloseDialog1 = () => {
    setOpen(false);
    localStorage.removeItem("WorkoutExerciseListSearch");
    setFilterState(EMPTY_FILTER);
    setFilterCount(0);
    setFilterName("");
  };
  const [selectedTab, setSelectedTab] = useState(tabs.asc);
  const [selectedExercise, setSelectedExercise] = useState([]);
  const [selectedExerciseTags, setSelectedExerciseTags] = useState([]);
  const [selectedDifficultyTags, setSelectedDifficultyTags] = useState([]);
  const [selectedMainMuscleTags, setSelectedMainMuscleTags] = useState([]);
  const [girdMode, setGirdMode] = useState(true);
  const id = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [exercise, setExercise] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [oldFilterName, setOldFilterName] = useState("");
  const [openExerciseDetails, setOpenExerciseDetails] = useState(false);
  const [exerciseDetails, setExerciseDetails] = useState(null);

  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [exercisetag, setExerciseTag] = useState([]);
  const [difficulty, setDifficulty] = useState([]);
  const [main_muscle, setMainMuscle] = useState([]);
  const [conditionMetOnce, setConditionMetOnce] = useState(false);

  const EMPTY_FILTER = {
    exercises: [],
    mainMuscle: [],
    difficulty: [],
    search_text: "",
    exercise_type: null,
  };

  const [filterState, setFilterState] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState({});
  // const [filterStateUpdated, setFilterStateUpdated] = useState({EMPTY_FILTER});
  const [openDetails, setOpenDetails] = useState(false);
  const searchFunction = () => {
    setPage(0);
    // setPageCount(1);
    filterState.search_text = filterName;
    getExerciseList(filterState);
    localStorage.setItem(
      "WorkoutExerciseListSearch",
      JSON.stringify(filterState)
    );

    handleCloseFilterDrawer();
    setIsLoading(true);
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    // setPageCount(1);

    getExerciseList(data);
    setFilterState(data);
    localStorage.setItem("WorkoutExerciseListSearch", JSON.stringify(data));
  };
  const handleScroll = (event) => {
    console.log("called scroll");
    fetchMoreData();
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    if (Math.ceil(scrollTop + clientHeight) == scrollHeight) {
      fetchMoreData();
    }
  };

  const handleOpenExerciseDetails = (data) => {
    setExerciseDetails(data);
    setOpenExerciseDetails(true);
  };

  const handleClearFilter = () => {
    let clear_data = { ...EMPTY_FILTER };
    setPage(0);
    // setPageCount(1);
    setFilterName("");
    setFilterState(clear_data);

    getExerciseList(clear_data);
    handleCloseFilterDrawer();
    setIsLoading(true);
    localStorage.removeItem("WorkoutExerciseListSearch");
  };
  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
    setFilterState(filterStateUpdated);
  };
  const handleOpenAddExercise = () => {
    setOpenDetails(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleSelectExercise = (value) => {
    if (isWithHistory) {
      handleChange(value);
      handleCloseDialog1();
      localStorage.removeItem("WorkoutExerciseListSearch");
      setFilterState(EMPTY_FILTER);
      setFilterCount(0);
      setFilterName("");
    } else {
      if (selectedExercise.find((exercise) => exercise._id === value._id)) {
        // setSelectedExercise((prev) =>
        //   prev.filter((exercise) => exercise._id !== value._id)
        // );
      } else {
        setSelectedExercise((prev) => [...prev, value]);
      }
    }
  };

  const handleConfirmAdd = () => {
    handleChange(selectedExercise);
    handleCloseDialog1();
    localStorage.removeItem("WorkoutExerciseListSearch");
    setFilterState(EMPTY_FILTER);
    setFilterCount(0);
    setFilterName("");
    setSelectedExercise([]);
  };

  const handleChipDelete = (item) => {
    setSelectedExercise((prev) =>
      prev.filter((exercise) => exercise._id !== item._id)
    );
  };

  const fetchMoreData = () => {
    console.log("called or not");
    setPage((prev) => prev + 1);
  };
  useEffect(() => {
    if (filterName !== oldFilterName) {
      setOldFilterName(filterName);
    }
  }, [filterName]);
  const getExerciseList = async (filterData) => {
    setOldFilterName(filterName);
    let postdata = {
      difficulty: filterData.difficulty,
      exercise: filterData.exercises,
      main_muscle: filterData.mainMuscle,
      exercise_type: filterData?.exercise_type
        ? filterData?.exercise_type._id
        : "",
    };
    let result = await ExerciseListingApi(
      page,
      rowsPerPage,
      filterName,
      postdata,
      selectedTab
    );
    if (result.code == 200) {
      let filtersCount =
        filterData?.difficulty.length +
        filterData?.exercises.length +
        filterData?.mainMuscle.length;
      setFilterCount(
        filterData?.difficulty.length +
          filterData?.exercises.length +
          filterData?.mainMuscle.length
      );
      if (filterData?.status) {
        setFilterCount((values) => values + 1);
        filtersCount = filtersCount + 1;
      }
      if (filterData?.exercise_type) {
        setFilterCount((values) => values + 1);
        filtersCount = filtersCount + 1;
      }
      let chipData = { ...filterData };
      if (chipData.search_text) {
        delete chipData.search_text;
      }
      setFilterStateUpdated(chipData);
      setIsLoading(false);
      const newData = result?.exercises;
      setTotalCount(result.total_exercises);
      // Check if newData is an array and has data
      if (!filtersCount && !filterCount && !filterName) {
        setConditionMetOnce(false);
      }
      if ((filtersCount || filterCount || filterName) && !conditionMetOnce) {
        setExercise(result?.exercises);
        setPage(0);
        setConditionMetOnce(true);
        // console.log(conditionMetOnce, "sadlkjfjsdlklklka");
        return;
      } else {
        let mergedData;
        if (page === 0) {
          mergedData = newData;
        } else {
          mergedData = [...exercise, ...newData];
        }
        // console.log(newData, "newDatanewDatanewData");

        // const mergedData = [
        //   ...exercise,
        //   ...newData.filter(
        //     (item) =>
        //       !exercise.find((existingItem) => existingItem._id === item._id)
        //   ),
        // ];
        // if (filterName !== oldFilterName) {
        //   mergedData = newData;
        // } else {
        //   mergedData = [
        //     ...exercise,
        //     ...newData.filter(
        //       (item) =>
        //         !exercise.find((existingItem) => existingItem._id === item._id)
        //     ),
        //   ];
        // }
        let sortedMergedData = [...mergedData];
        let sortedMergedDataagain = [...mergedData];

        if (selectedTab === "asc") {
          sortedMergedData.sort((a, b) => {
            // Customize the comparison logic based on your data structure
            // Assuming you want to sort based on some property like title
            return a.title.localeCompare(b.title);
          });
        } else if (selectedTab === "desc") {
          sortedMergedData.sort((a, b) => {
            // Customize the comparison logic based on your data structure
            // Assuming you want to sort based on some property like title
            return b.title.localeCompare(a.title);
          });
        }
        if (filterName) {
          const word = filterName.toLowerCase();
          // console.log(word, "word searched");
          sortedMergedData.sort((a, b) => {
            const startsWithA = a.title.toLowerCase().startsWith(word);
            const startsWithB = b.title.toLowerCase().startsWith(word);

            // If only one of them starts with the search term, that one should come first
            if (startsWithA && !startsWithB) {
              return -1;
            }
            if (!startsWithA && startsWithB) {
              return 1;
            }
            // If neither or both start with the search term, sort alphabetically
            return a.title.localeCompare(b.title);
          });
        }

        if (filterName !== "") {
          setExercise(sortedMergedDataagain);
        } else {
          setExercise(sortedMergedData);
        }
        // setExercise(sortedMergedDataagain);
      }

      // setExercise(sortedMergedData);
      // setTotalPages(result.total_pages);
    }
  };
  const getExerciseTagesListing = async () => {
    setIsLoading(true);

    try {
      setIsLoading(true);

      const result = await ExerciseTagesApi();
      if (result.code === 200) {
        setExerciseTag(result?.tags?.exercise);
        setDifficulty(result?.tags?.difficulty);
        setMainMuscle(result?.tags?.main_muscle);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    getExerciseTagesListing();
  }, []);

  useEffect(() => {
    if (!openDetails && open) {
      let filter_data = EMPTY_FILTER;
      let find_filter = localStorage.getItem("WorkoutExerciseListSearch");
      if (find_filter) {
        filter_data = JSON.parse(find_filter);
        if (filter_data.search_text) {
          setFilterName(filter_data.search_text);
        }
      }
      setFilterState(filter_data);
      setFilterStateUpdated(filter_data);

      getExerciseList(filter_data);
    }
    // filterState.search_text = filterName;
    // getExerciseList(filterState);
  }, [page, rowsPerPage, open, openDetails, selectedTab]);
  useEffect(() => {
    // Delay execution of getExerciseList only when filterName changes
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("WorkoutExerciseListSearch");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      // if (filter_data.search_text) {
      //   setFilterName(filter_data.search_text);
      // }
    }
    setFilterState(filter_data);
    setFilterStateUpdated(filter_data);
    const timer = setTimeout(() => {
      setPage(0);
      getExerciseList(filter_data);
    }, 500);

    // Clean up function to clear the timer when filterName changes again or component unmounts
    return () => clearTimeout(timer);
  }, [filterName]);
  useEffect(() => {
    setIsLoading(true);
    setPage(0);
    setExercise([]);
  }, [selectedTab, open]);

  // if (isLoading === true) {
  //   return <CircularProgress className={classes.loading} color="primary" />;
  // }
  console.log(exerciseDetails, "exerciseDetails");

  return (
    <>
      {/* New to solve delete issue  */}
      <Dialog
        open={open}
        onClose={handleCloseDialog1}
        // scroll="body"
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
            minHeight: "550px",
          },
        }}
      >
        <div className="d-flex justify-content-between align-items-center ">
          <div className="row w-100  ">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <DialogTitle> Exercises</DialogTitle>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12">
              <div className=" me-1">
                <div className="text-end ">
                  <TextField
                    fullWidth
                    size="small"
                    label="Search"
                    sx={{ marginRight: 1, width: 240, mt: 3 }}
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                  />
                  <button
                    className="small-contained-button me-2  mt-4 mb-4"
                    onClick={handleOpenFilterDrawer}
                    style={{ position: "relative" }}
                  >
                    Filters &nbsp;&nbsp;{" "}
                    <Icon width={19} height={19} icon={roundFilterList} />
                    <span
                      // className="small-contained-button"
                      style={{
                        position: "absolute",
                        bottom: "59%",
                        right: "8%",
                      }}
                    >
                      {filterCount ? (
                        <div className="dot-filter-count">{filterCount}</div>
                      ) : (
                        <div></div>
                      )}
                    </span>
                  </button>
                  <button
                    className="small-contained-button me-2  mt-4 mb-4"
                    onClick={handleOpenAddExercise}
                  >
                    Add Exercise
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 mx-4">
              <div className="d-flex align-items-center">
                <label className="fw_14">VIEW AS </label>
                <div
                  className={`toolbar-item ${girdMode ? "active" : ""}`}
                  onClick={() => setGirdMode(true)}
                >
                  <TableRowsIcon
                    style={{
                      fontSize: 20,
                      fill: "var(--portal-theme-primary)",
                    }}
                  />
                </div>
                <div
                  className={`toolbar-item ${girdMode ? "" : "active"}`}
                  onClick={() => setGirdMode(false)}
                >
                  <GridOnIcon
                    style={{
                      fontSize: 20,
                      fill: "var(--portal-theme-primary)",
                    }}
                  />
                </div>
                <div className="d-flex mb-1">
                  <div className="ms-3 me-2 pointer">
                    <Label
                      onClick={() => {
                        if (filterName !== "") {
                        } else {
                          setSelectedTab(tabs.asc);
                        }
                      }}
                      // onClick={() => console.log("fksjdfha")}
                      variant={
                        filterName !== ""
                          ? ""
                          : selectedTab === tabs.asc
                          ? "ghost"
                          : ""
                      }
                      style={{
                        color:
                          selectedTab === tabs.asc
                            ? "var(--portal-theme-primary)"
                            : "",
                        backgroundColor:
                          selectedTab === tabs.asc
                            ? "var(--portal-theme-secondary)"
                            : "",
                        border:
                          selectedTab === tabs.asc
                            ? "1px solid var(--portal-theme-primary)"
                            : "",
                        width: 50,
                        cursor: filterName !== "" ? "default" : "pointer",
                      }}
                      color="info"
                    >
                      A-Z
                    </Label>
                  </div>
                  <div className="me-2 pointer">
                    <Label
                      onClick={() => {
                        if (filterName !== "") {
                        } else {
                          setSelectedTab(tabs.close);
                        }
                      }}
                      variant={
                        filterName !== ""
                          ? ""
                          : selectedTab === tabs.close
                          ? "ghost"
                          : ""
                      }
                      style={{
                        color:
                          selectedTab === tabs.close
                            ? "var(--portal-theme-primary)"
                            : "",
                        backgroundColor:
                          selectedTab === tabs.close
                            ? "var(--portal-theme-secondary)"
                            : "",
                        border:
                          selectedTab === tabs.close
                            ? "1px solid var(--portal-theme-primary)"
                            : "",
                        width: 50,
                        cursor: filterName !== "" ? "default" : "pointer",
                      }}
                      color="info"
                    >
                      Z-A
                    </Label>
                  </div>
                </div>
              </div>
            </div>
            {!isWithHistory && selectedExercise.length > 0 && (
              <div className="col-12 mx-4 selected-exercise-chip">
                {selectedExercise.map((item) => {
                  return (
                    <Chip
                      style={{
                        marginRight: 10,
                        marginTop: 10,
                        backgroundColor: "var(--portal-theme-secondary)",
                      }}
                      label={item.title}
                      onDelete={() => {
                        handleChipDelete(item);
                      }}
                      deleteIcon={
                        <CancelIcon
                          style={{ fill: "var(--portal-theme-primary)" }}
                        />
                      }
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <DialogContent sx={{ overflow: "hidden" }}>
          {/* <DialogContent onScroll={(event) => handleScroll(event)}> */}
          {isLoading ? (
            <CircularProgress className={classes.loading} color="primary" />
          ) : (
            <div className="">
              <CustomDrawer
                isOpenDrawer={filterDrawerState}
                onOpenDrawer={handleOpenFilterDrawer}
                onCloseDrawer={handleCloseFilterDrawer}
                pageTitle="Filters"
                componentToPassDown={
                  <ExerciseFilter
                    filterData={filterState}
                    setFilterData={setFilterState}
                    searchSubmitFilter={searchFunction}
                    handleClearFilter={handleClearFilter}
                    exercise={exercisetag}
                    difficulty={difficulty}
                    main_muscle={main_muscle}
                  />
                }
              />
              <ToshowAddExercise open={openDetails} setOpen={setOpenDetails} />
              {/* <div className="row mobile-margin display-flex mt-2">
                <div className="col-lg-12 col-sm-12 text-end ">
                  <TextField
                    fullWidth
                    size="small"
                    label="Search"
                    sx={{ marginRight: 2, width: 240 }}
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                  />
                  <button
                    className="small-contained-button me-2 mt-0 mb-4"
                    onClick={handleOpenFilterDrawer}
                  >
                    Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
                  </button>
                </div>
                <div className="col-lg-12 col-sm-12 d-flex justify-content-end"></div>
              </div> */}

              <div className="row">
                {exercise.length < 1 ? (
                  <RecordNotFound title="Exercises" />
                ) : (
                  <>
                    <InfiniteScroll
                      className="w-100 m-auto pb-4"
                      dataLength={exercise.length}
                      next={fetchMoreData}
                      // height={400}
                      height="calc(100vh - 200px)"
                      hasMore={exercise.length < totalCount ? true : false} // Set this to 'false' when you have loaded all available data.
                      loader={<h4 className="text-center">Loading...</h4>}
                    >
                      <div className="row w-100 m-auto mb-3">
                        {exercise.map((x, i) => {
                          return (
                            <>
                              {girdMode ? (
                                <div
                                  className="col-12 col-md-6 mb-1 px-0"
                                  key={i}
                                >
                                  <div
                                    className="card exercise_cards h-100"
                                    style={{
                                      border: "none",
                                    }}
                                    onClick={() => handleSelectExercise(x)}
                                  >
                                    <div className="px-2 py-1">
                                      <div className="d-flex align-items-center h-100">
                                        <img
                                          src={
                                            s3baseUrl + x?.image?.thumbnail_1
                                          }
                                          alt="workout"
                                          className="me-2"
                                          style={{
                                            width: "25px",
                                          }}
                                        />
                                        <div className="h2-heading fw-bold">
                                          {htmlDecode(x?.title)}
                                        </div>
                                        <div className="ms-auto d-flex align-items-center">
                                          {!isWithHistory && (
                                            <>
                                              {selectedExercise.find(
                                                (exercise) =>
                                                  exercise._id === x._id
                                              ) ? (
                                                ""
                                              ) : (
                                                <div className="hover_icon">
                                                  <AddCircleIcon
                                                    style={{
                                                      fill: "var(--portal-theme-primary)",
                                                      fontSize: 20,
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                </div>
                                              )}
                                            </>
                                          )}
                                          <div className="hover_icon ms-1">
                                            <VisibilityIcon
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleOpenExerciseDetails(x);
                                              }}
                                              style={{
                                                fill: "var(--portal-theme-primary)",
                                                fontSize: 22,
                                                cursor: "pointer",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="col-lg-3 col-md-3 col-sm-12 mb-4"
                                  key={i}
                                >
                                  <div
                                    className="card mt-1 exercise-cards-shadow ms-1 cursor h-100"
                                    onClick={() => handleSelectExercise(x)}
                                  >
                                    <img
                                      src={s3baseUrl + x?.image?.thumbnail_1}
                                      className="card-img-top pods-image"
                                      alt="workout"
                                    />
                                    <div className="card-body">
                                      <div className="row d-flex align-items-end h-100">
                                        <div className="col-12 ">
                                          <div className="h2-heading fw-bold d-flex align-items-center justify-content-between">
                                            {htmlDecode(x?.title)}

                                            <div className="d-flex align-items-center">
                                              {!isWithHistory && (
                                                <>
                                                  {selectedExercise.find(
                                                    (exercise) =>
                                                      exercise._id === x._id
                                                  ) ? (
                                                    ""
                                                  ) : (
                                                    <div className="hover_icon">
                                                      <AddCircleIcon
                                                        style={{
                                                          fill: "var(--portal-theme-primary)",
                                                          fontSize: 20,
                                                          cursor: "pointer",
                                                        }}
                                                      />
                                                    </div>
                                                  )}
                                                </>
                                              )}
                                              <div className="hover_icon ms-1">
                                                <VisibilityIcon
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleOpenExerciseDetails(
                                                      x
                                                    );
                                                  }}
                                                  style={{
                                                    fill: "var(--portal-theme-primary)",
                                                    fontSize: 22,
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })}
                      </div>
                    </InfiniteScroll>
                  </>
                )}
              </div>
            </div>
          )}
        </DialogContent>

        <DialogActions>
          {/* <Button className="model-button-hover" onClick={handleCloseDialog1}>
            close
          </Button> */}
          {selectedExercise.length > 0 && (
            <Button variant="contained" onClick={() => handleConfirmAdd()}>
              Save Exercise
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {exerciseDetails && (
        <CustomPopover
          isOpenPop={openExerciseDetails}
          isClosePop={setOpenExerciseDetails}
          title={exerciseDetails?.title}
          width="100%"
          componentToPassDown={
            <div className="px-4 pb-3">
              {exerciseDetails?.video_url ? (
                <ReactVideoPlayer url={exerciseDetails?.video_url} />
              ) : exerciseDetails?.gif_url ? (
                <div className="w-100 d-flex justify-content-center">
                  <img src={s3baseUrl + exerciseDetails?.gif_url} />
                </div>
              ) : (
                <img
                  width="100%"
                  src={s3baseUrl + exerciseDetails?.image?.thumbnail_1}
                />
              )}
              {exerciseDetails?.instructions && (
                <div className="mt-3">
                  <Typography
                    style={{ color: "var(--portal-theme-primary)" }}
                    variant="h6"
                  >
                    Instructions
                  </Typography>
                  <Typography variant="body1">
                    {exerciseDetails?.instructions}
                  </Typography>
                </div>
              )}
            </div>
          }
        />
      )}
    </>
  );
}

export default ToshowExercise;
