import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { htmlDecode } from "src/utils/convertHtml";
import moment from "moment";

export default function EventDetailNew({ eventDetailData }) {
  return (
    <>
      <div className="event-details">
        <h1>{htmlDecode(eventDetailData.event_title)}</h1>
        <div className="row">
          <div className="col-12 mt-1">
            <div className="d-flex flex-column flex-md-row">
              <div className="d-flex me-4">
                <span>From : </span>
                <p className="ms-1 date-color">
                  {moment(eventDetailData.start_date_time).format(
                    "DD-MM-YYYY hh:mm A"
                  )}
                </p>
              </div>
              <div className="d-flex">
                <span>To : </span>
                <p className="ms-1 date-color">
                  {moment(eventDetailData.end_date_time).format(
                    "DD-MM-YYYY hh:mm A"
                  )}
                </p>
              </div>
            </div>
            {eventDetailData.description && (
              <>
                <div className="col-12 mb-3">
                  <b>Description :</b>&nbsp;
                </div>
                <div className="col-12">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: eventDetailData.description,
                    }}
                  ></div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
