import {
  Avatar,
  CircularProgress,
  IconButton,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import Markdown from "markdown-to-jsx";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { useChat } from "src/Hooks/ChatContext";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { s3baseUrl } from "src/config/config";
import {
  string_avatar,
  getTimeBasedOnDate,
  get_local_preview,
} from "src/utils/constant";
import LinkPopup from "./LinkPopup";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import CustomPopover from "src/components/MenuOption/CustomPopover";
import { update_image_on_s3_for_chat } from "src/DAL/Chat/Chat";

function ChatMessages({ is_popup }) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectedChat,
    selectedUser,
    loadMoreData,
    messagesList,
    lastBookElementRef,
    setSelectedMessage,
    selectedMessage,
    isLoadingMore,
    isLoadingChat,
    checkLoadMore,
  } = useChat();
  const inputRef = useRef(null);
  const { userInfo, socket } = useContentSetting();
  const [isLoading, setIsLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [message, setMessage] = useState("");
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [openFormatter, setOpenFormatter] = useState(false);
  const [openLink, setOpenLink] = useState(false);
  const [inputHeight, setInputHeight] = useState(0);

  const receiver_profile = selectedChat?.receiver_profile;
  const my_profile = selectedChat?.my_profile;

  const handleUploadFile = (e) => {
    const _file = e.target.files[0];
    setFile(_file);
  };

  const handleRemoveFile = (e) => {
    setFile(null);
    setImage(null);
  };

  const handleCancelUpdate = async () => {
    setSelectedMessage(null);
    setMessage("");
    setFile(null);
    setImage(null);
    setIsUpdate(false);
  };

  const handleEdit = async (message_obj) => {
    setSelectedMessage(message_obj);
    setMessage(message_obj?.content);
    setImage(message_obj?.image);
    setIsUpdate(true);
  };

  const handleSendMessage = async (receiver_id, action) => {
    let image = "";
    let is_error = false;
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("width", 600);
      formData.append("height", 700);
      const result = await update_image_on_s3_for_chat(formData);
      if (result.code === 200) {
        image = result.image_path;
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        is_error(true);
      }
    }
    if (is_error) {
      setIsLoading(false);
      return;
    }
    if (!message && !image) {
      setIsLoading(false);
      enqueueSnackbar("Please enter a message", { variant: "error" });
      return;
    }
    const postData = {
      chat_with: receiver_id,
      message_content: message,
      image: image,
      created_by: action,
      creator_id: userInfo._id,
    };
    socket.emit("send_chat_message", postData);
    setMessage("");
    setFile(null);
    setImage(null);
    setIsLoading(false);
  };

  const handleSubmitMessage = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (isUpdate) {
      handleUpdateMessage();
      return;
    }
    if (selectedUser) {
      handleSendMessage(selectedUser.chatId, "admin_user");
    } else {
      handleSendMessage(selectedChat.receiver_profile.id, "admin_user");
    }
  };

  const handleDelete = async (message_obj) => {
    setSelectedMessage(message_obj);
    setOpenDelete(true);
  };

  const handleAgreeDelete = async () => {
    setOpenDelete(false);
    const postData = {
      message_id: selectedMessage.messageId,
      creator_id: userInfo._id,
      chat_with: selectedChat.receiver_profile.id,
    };
    socket.emit("delete_chat_message", postData);
  };

  const handleUpdateMessage = async () => {
    let _image = image;
    let is_error = false;
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("width", 600);
      formData.append("height", 700);
      const result = await update_image_on_s3_for_chat(formData);
      if (result.code === 200) {
        _image = result.image_path;
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        is_error(true);
      }
    }
    if (is_error) {
      setIsLoading(false);
      return;
    }
    if (!message && !_image) {
      setIsLoading(false);
      enqueueSnackbar("Please enter a message", { variant: "error" });
      return;
    }

    const postData = {
      message_id: selectedMessage.messageId,
      new_message_content: message,
      creator_id: userInfo._id,
      chat_with: selectedChat.receiver_profile.id,
      image: _image,
    };
    setMessage("");
    setFile(null);
    setImage(null);
    setSelectedMessage(null);
    setIsUpdate(false);
    setIsLoading(false);
    socket.emit("update_chat_message", postData);
  };

  const handleGetSelectedText = () => {
    const inputField = document.getElementById("inputField");
    if (inputField) {
      const selectedText = inputField.value.substring(
        inputField.selectionStart,
        inputField.selectionEnd
      );
      return selectedText;
    }
  };

  const handleOnBold = () => {
    let seleted_text = handleGetSelectedText();
    if (seleted_text) {
      var newText = inputField.value.replace(
        seleted_text,
        "**" + seleted_text + "**"
      );
      setMessage(newText);
    }
  };

  const handleOnItalic = () => {
    let seleted_text = handleGetSelectedText();
    if (seleted_text) {
      var newText = inputField.value.replace(
        seleted_text,
        "*" + seleted_text + "*"
      );
      setMessage(newText);
    }
  };

  const handleAddLink = (values) => {
    let make_link = ` [${values.display_text}](${values.link_address})`;
    const inputField = document.getElementById("inputField");
    const start = inputField.selectionStart;
    const end = inputField.selectionEnd;
    setMessage(message.slice(0, start) + make_link + message.slice(end));
    inputField.setSelectionRange(
      start + make_link.length,
      start + make_link.length
    );
    setOpenLink(false);
  };

  const handleOpenLinkPopup = () => {
    setOpenLink(true);
  };

  const handleToggleFormatter = () => {
    let heigh = 0;
    let marginTop = 0;
    let paddingTop = 0;
    let elements = document.getElementsByClassName("chat-formatters-box")[0];
    if (!openFormatter) {
      setTimeout(() => {
        setOpenFormatter(!openFormatter);
      }, 100);
      heigh = "35px";
    } else {
      setOpenFormatter(!openFormatter);
    }
    elements.style.height = heigh;
    elements.style.marginTop = marginTop;
    elements.style.paddingTop = paddingTop;
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleDelete,
    },
  ];

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmitMessage(e);
    }
  };

  useEffect(() => {
    let input_element = document.getElementById("chat-right-bottom");
    if (input_element) {
      setTimeout(() => {
        let input_height = input_element.offsetHeight;
        setInputHeight(input_height);
      }, 200);
    }
  }, [message, openFormatter]);

  const renderLink = (props) => (
    <a {...props} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );

  return (
    <>
      {!selectedChat ? (
        <div className="d-flex justify-content-center align-items-center no-chat-wrapper">
          <div className="no-chat-div">
            <Icon
              className="no-chat-image"
              icon="material-symbols:chat-bubble-outline"
            />
          </div>
        </div>
      ) : (
        <div
          className="user-chat-box-body ps-2 pe-2"
          style={
            is_popup
              ? {
                  paddingBottom: "60px",
                  overflow: isLoadingMore ? "hidden" : "scroll",
                  backgroundColor: "#F7F7F7",
                }
              : {
                  height: `calc(60vh)`,
                  overflow: isLoadingMore ? "hidden" : "scroll",
                }
          }
          id="chat_box_body"
        >
          {isLoadingChat ? (
            <div className="d-flex justify-content-center">
              <CircularProgress
                style={{ width: 40, height: 40, marginTop: "25%" }}
                color="primary"
              />
            </div>
          ) : (
            <>
              {selectedChat && checkLoadMore && (
                <div className="text-center">
                  {isLoadingMore ? (
                    <CircularProgress
                      className="mt-3"
                      style={{ width: 20, height: 20 }}
                      color="primary"
                    />
                  ) : (
                    <span
                      ref={lastBookElementRef}
                      onClick={loadMoreData}
                      id="load-more-messages"
                    >
                      <CircularProgress
                        className="mt-3"
                        onClick={loadMoreData}
                        style={{ width: 20, height: 20 }}
                        color="primary"
                      />
                    </span>
                  )}
                </div>
              )}
              {messagesList.map((message_obj, i) => {
                return (
                  <>
                    {message_obj?.is_self ? (
                      <div
                        className="w-100 d-flex justify-content-end control-dots"
                        key={message_obj?._id}
                      >
                        <div className="chat-message-dots">
                          <CustomPopover
                            menu={MENU_OPTIONS}
                            data={message_obj}
                          />
                        </div>
                        <div
                          className={`chat-outgoing ${
                            message_obj?.message_type == "video" &&
                            message_obj?.embed_code
                              ? "w-85"
                              : ""
                          }`}
                          style={{
                            backgroundColor: "var(--portal-theme-secondary)",
                          }}
                        >
                          {message_obj?.image && (
                            <img
                              className="mb-2"
                              src={s3baseUrl + message_obj?.image}
                            ></img>
                          )}
                          {!!message_obj?.content && (
                            <Markdown
                              options={{
                                overrides: { a: { component: renderLink } },
                              }}
                            >
                              {message_obj?.content}
                            </Markdown>
                          )}
                          <Tooltip
                            title={getTimeBasedOnDate(
                              message_obj?.createdAt,
                              "DD MMM YYYY [at] hh:mm A"
                            )}
                            arrow
                          >
                            <span className="message-time">
                              {getTimeBasedOnDate(
                                message_obj?.createdAt,
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </span>
                          </Tooltip>
                        </div>
                        {!is_popup && (
                          <Avatar
                            src={s3baseUrl + my_profile?.profileImage}
                            style={{ height: 35, width: 35, marginTop: 10 }}
                          >
                            {string_avatar(my_profile?.name[0])}
                          </Avatar>
                        )}
                      </div>
                    ) : (
                      <div
                        className="w-100 d-flex justify-content-start"
                        key={message_obj?._id}
                      >
                        {!is_popup && (
                          <Avatar
                            src={s3baseUrl + receiver_profile?.profileImage}
                            style={{ height: 35, width: 35, marginTop: 10 }}
                          >
                            {string_avatar(receiver_profile?.name[0])}
                          </Avatar>
                        )}
                        <div
                          className={`chat-incoming ${
                            message_obj?.message_type == "video" &&
                            message_obj?.embed_code
                              ? "w-85"
                              : ""
                          }`}
                        >
                          {message_obj?.image && (
                            <img
                              className="mb-2"
                              src={s3baseUrl + message_obj?.image}
                            ></img>
                          )}
                          {!!message_obj?.content && (
                            <Markdown
                              options={{
                                overrides: { a: { component: renderLink } },
                              }}
                            >
                              {message_obj?.content}
                            </Markdown>
                          )}
                          <Tooltip
                            title={getTimeBasedOnDate(
                              message_obj?.createdAt,
                              "DD MMM YYYY [at] hh:mm A"
                            )}
                            arrow
                          >
                            <span className="message-time">
                              {getTimeBasedOnDate(
                                message_obj?.createdAt,
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </>
          )}
        </div>
      )}
      <div
        className="chat-right-bottom"
        id="chat-right-bottom"
        style={{
          position: is_popup ? "absolute" : "",
        }}
      >
        {(receiver_profile || is_popup) && (
          <div
            className="chat-send-message-wrapper"
            style={{
              backgroundColor: is_popup ? "#f7f7f7" : "#ffffff",
            }}
          >
            <form
              onSubmit={handleSubmitMessage}
              className="d-flex align-items-end w-100"
            >
              {!file && !image && (
                <span className="">
                  <input
                    color="primary"
                    accept="image/*"
                    type="file"
                    id="message-chat-upload-button"
                    style={{ display: "none" }}
                    name=""
                    onChange={handleUploadFile}
                  />
                  <label>
                    <Tooltip title="Add Image" placement="top">
                      <IconButton
                        onClick={() => {
                          document.getElementById(
                            "message-chat-upload-button"
                          ) &&
                            document
                              .getElementById("message-chat-upload-button")
                              .click();
                        }}
                        className="upload-image-icon mb-2"
                      >
                        <AddPhotoAlternateIcon
                          style={{ fill: "var(--portal-theme-primary)" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </label>
                </span>
              )}

              <div className="w-100">
                <div
                  className={`chat-formatters-box d-flex ${
                    openFormatter ? "chat-formatters-border" : ""
                  }`}
                  style={{
                    backgroundColor: "#ffffff",
                  }}
                >
                  {openFormatter && (
                    <>
                      <div className="formatter-icon" onClick={handleOnBold}>
                        <Icon
                          fontSize="15"
                          style={{ color: "var(--portal-theme-primary)" }}
                          icon="octicon:bold-24"
                        />
                      </div>
                      <div className="formatter-icon" onClick={handleOnItalic}>
                        <Icon
                          fontSize="15"
                          style={{ color: "var(--portal-theme-primary)" }}
                          icon="fe:italic"
                        />
                      </div>
                      <div
                        className="formatter-icon"
                        onClick={handleOpenLinkPopup}
                      >
                        <Icon
                          fontSize="15"
                          style={{ color: "var(--portal-theme-primary)" }}
                          icon="ion:link"
                        />
                      </div>
                    </>
                  )}
                </div>
                {image && (
                  <div
                    className="chat-image-preview-box"
                    style={{
                      borderRadius: openFormatter ? "0px" : "15px 15px 0 0",
                      backgroundColor: is_popup
                        ? "var(--background-primary-color) "
                        : "var(--background-secondary-color)",
                    }}
                  >
                    <div className="chat-preview-image chat-image">
                      <img
                        className="chat-preview-image"
                        src={s3baseUrl + image}
                        alt=""
                      />

                      <Icon
                        className="cross"
                        onClick={handleRemoveFile}
                        icon="charm:circle-cross"
                      />
                    </div>
                  </div>
                )}
                {file && (
                  <div
                    className="chat-image-preview-box"
                    style={{
                      borderRadius: openFormatter ? "0px" : "15px 15px 0 0",
                      backgroundColor: is_popup
                        ? "#0d0d0d"
                        : "var(--background-secondary-color)",
                    }}
                  >
                    <div className="chat-preview-image chat-image">
                      <img
                        className="chat-preview-image"
                        src={get_local_preview(file)}
                        alt=""
                      />

                      <Icon
                        className="cross"
                        onClick={handleRemoveFile}
                        icon="charm:circle-cross"
                      />
                    </div>
                  </div>
                )}
                <div className="position-relative">
                  <TextareaAutosize
                    ref={inputRef}
                    id="inputField"
                    className={`chat-send-input ${
                      file || image || openFormatter ? "border-with-image" : ""
                    }`}
                    style={{
                      height: is_popup ? "50px" : "25px",
                      backgroundColor: "#ffffff",
                    }}
                    type="text"
                    value={message}
                    onKeyDown={handleKeyDown}
                    maxRows={6}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Write your message..."
                    required={!(image || file)}
                    autoFocus
                  />
                  <Icon
                    fontSize={20}
                    className="show-hide-chat-formatter"
                    style={{
                      color: "var(--portal-theme-primary)",
                    }}
                    icon={`${
                      openFormatter
                        ? "mingcute:down-line"
                        : "fluent:text-edit-style-16-regular"
                    }`}
                    onClick={handleToggleFormatter}
                  />
                </div>
              </div>
              {(message || image || file) && !isLoading && (
                <IconButton type="submit" className="ms-2 mb-2">
                  <SendIcon style={{ fill: "var(--portal-theme-primary)" }} />
                </IconButton>
              )}
              {isLoading && (
                <IconButton className="ms-2 mb-2">
                  <CircularProgress
                    style={{
                      width: 23,
                      height: 23,
                    }}
                  />
                </IconButton>
              )}
              {isUpdate && (
                <IconButton onClick={handleCancelUpdate} className="ms-2 mb-2">
                  <CloseIcon style={{ fill: "var(--portal-theme-primary)" }} />
                </IconButton>
              )}
            </form>
          </div>
        )}
      </div>
      <CustomConfirmation
        title={"Are you sure you want to delete this message?"}
        open={openDelete}
        setOpen={setOpenDelete}
        handleAgree={handleAgreeDelete}
      />

      <GeneralPopUpModel
        open={openLink}
        setOpen={setOpenLink}
        title={"Insert Link"}
        componentToPassDown={<LinkPopup handleAddLink={handleAddLink} />}
      />
    </>
  );
}

export default ChatMessages;
